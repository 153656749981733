import request from '@/utils/request'

export function login (data) {
    return request({
        url: '/login/login',
        method: 'post',
        data
    })
}
//退出登录
export function loginOut (data) {
    // http://192.168.1.199:93/admin/login/logout
    return request({
        url: '/login/logout',
        method: 'post',
        data
    })
}
// 192.168.0.200:8888/pc/modify_pwd?old_pwd=123&pwd=123456
//修改密码
export function updataPwd (data) {
    http://192.168.1.199:93/admin/login/modify
    return request({
        url: '/login/modify',
        method: 'post',
        data
    })
}
//创建物料
export function createMaterial (data) {
    return request({
        url: '/material/create',
        method: 'post',
        data
    })
}
// http://192.168.1.199:93/admin/material/index?limit=30&page=1
export function getMaterialList (data) {
    return request({
        url: '/material/index',
        method: 'get',
        params: data
    })
}
// http://192.168.1.199:93/admin/material/delete_material
export function delete_material (data) {
    return request({
        url: '/material/delete_material',
        method: 'post',
        data
    })
}
// http://192.168.1.199:93/admin/material/infos?id=1
export function getMaterialInfo (data) {
    return request({
        url: '/material/infos',
        method: 'get',
        params: data
    })
}
export function selectMaterial (data) {
    return request({
        url: '/material/select',
        method: 'get',
        params: data
    })
}
// http://192.168.1.199:93/admin/active/index?limit=30&page=1&material_id=1&keywords=2

export function getActiveList (data) {
    return request({
        url: '/active/index',
        method: 'get',
        params: data
    })
}
// http://192.168.1.199:93/admin/active/create
export function active_create (data) {
    return request({
        url: '/active/create',
        method: 'post',
        data
    })
}
// http://192.168.1.199:93/admin/active/delete
export function active_delete (data) {
    return request({
        url: '/active/delete',
        method: 'post',
        data
    })
}
// http://192.168.1.199:93/admin/active/enter_export
export function enter_export (data) {
    return request({
        url: '/active/enter_export',
        method: 'post',
        data
    })
}
// http://192.168.1.199:93/admin/active/enter_list?limit=30&page=1&active_id=1
export function get_enter_list (data) {
    return request({
        url: '/active/enter_list',
        method: 'get',
        params: data
    })
}
// http://192.168.1.199:93/admin/active/active_export
export function active_export (data) {
    return request({
        url: '/active/active_export',
        method: 'post',
        data
    })
}
// http://form.hulianzaowu.top/admin/active/recommend_export
export function recommend_export (data) {
    return request({
        url: '/active/recommend_export',
        method: 'post',
        data
    })
}
// http://form.hulianzaowu.top/admin/active/unit_recommend_export
export function unit_recommend_export (data) {
    return request({
        url: '/active/unit_recommend_export',
        method: 'post',
        data
    })
}






//get      list    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export function getList (data) {
    return request({
        url: '/user/get_list',
        method: 'get',
        params: data
    })
}
//post     save   !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export function save (data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}
// 192.168.0.200:8888/pc/records
//预约记录
export function getRecordsList (data) {
    return request({
        url: '/records',
        method: 'post',
        data
    })
}

// http://192.168.1.199:93/admin/material/create

// 192.168.0.200:8888/pc/records/handle
//预约核销
export function handle (data) {
    return request({
        url: '/records/handle',
        method: 'post',
        data
    })
}
// 192.168.0.200:8888/pc/store/select_list
//门店下拉
export function store_select_list (data) {
    return request({
        url: '/store/select_list',
        method: 'post',
        data
    })
}

//门店列表
export function getStoreList (data) {
    return request({
        url: '/store',
        method: 'post',
        data
    })
}
// 创建或编辑门店
export function saveStore (data) {
    return request({
        url: '/store/create',
        method: 'post',
        data
    })
}
//删除门店
export function delStore (data) {
    return request({
        url: '/store/del',
        method: 'post',
        data
    })
}
//保存时间段
export function storeTime (data) {
    return request({
        url: '/store/time',
        method: 'post',
        data
    })
}


//保存经纬度
export function set_lat (data) {
    return request({
        url: '/store/set_lat',
        method: 'post',
        data
    })
}
//保存时间段
// 192.168.0.200:8888/pc/time/create
export function saveTime (data) {
    return request({
        url: '/time/create',
        method: 'post',
        data
    })
}
//删除时间段 
export function timeDel (data) {
    return request({
        url: '/time/del',
        method: 'post',
        data
    })
}
//时间段列表
export function getTimeList (data) {
    return request({
        url: '/time',
        method: 'post',
        data
    })
}
//时间段下拉
export function select_list (data) {
    return request({
        url: '/time/select_list',
        method: 'post',
        data
    })
}
// 192.168.0.200:8888/pc/time/select_list

// 创建banner
export function createBanner (data) {
    return request({
        url: '/banner/create',
        method: 'post',
        data
    })
}
// banner列表
export function banner (data) {
    return request({
        url: '/banner',
        method: 'post',
        data
    })
}
// 删除banner
export function delBanner (data) {
    return request({
        url: '/banner/del',
        method: 'post',
        data
    })
}
// 显示基本设置
export function basic (data) {
    return request({
        url: '/basic',
        method: 'post',
        data
    })
}
//保存基本设置
export function setBasic (data) {
    return request({
        url: '/basic/set',
        method: 'post',
        data
    })
}
// 192.168.0.200:8888/pc/store/address_lat
//保存基本设置
export function address_lat (data) {
    return request({
        url: '/store/address_lat',
        method: 'post',
        data
    })
}






export function get_list (data) {
    return request({
        url: '/user/get_list',
        method: 'get',
        params: data
    })
}

export function get_info (data) {
    return request({
        url: '/user/get_info',
        method: 'get',
        params: data
    })
}

export function edit_info (data) {
    return request({
        url: '/user/edit_info',
        method: 'post',
        data
    })
}

export function edit_status (data) {
    return request({
        url: '/user/edit_status',
        method: 'post',
        data
    })
}

export function del_album (data) {
    return request({
        url: '/user/del_album',
        method: 'post',
        data
    })
}

export function chat_list (data) {
    return request({
        url: '/user/chat_list',
        method: 'get',
        params: data
    })
}

export function chat_details (data) {
    return request({
        url: '/user/chat_details',
        method: 'get',
        params: data
    })
}

export function channel_list (data) {
    return request({
        url: '/user/channel_list',
        method: 'get',
        params: data
    })
}

export function edit_channel (data) {
    return request({
        url: '/user/edit_channel',
        method: 'post',
        data
    })
}

export function del_channel (data) {
    return request({
        url: '/user/del_channel',
        method: 'post',
        data
    })
}