import Vue from 'vue'
import VueRouter from 'vue-router'
import mainLayout from "@/components/mainLayout"
import appointment from "./modules/appointment"
import activity from "./modules/activity" //报价活动
import material from "./modules/material" //报价物料


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      name: '登录',
      path: '/',
      component: () => import("@/pages/login/login.vue")
    },
    {
      path: '/admin',
      meta: { title: '互联餐饮系统'},
      component: mainLayout,
      children: [
        ...appointment,      
        activity,
        material
      ]
    },
  ]
})

// const router = new VueRouter({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes
// })

router.beforeEach((to, from, next) => {
  window.document.title = "报价表单系统";
  next();
})
export default router
